<template>
    <div>
        <vx-card>
            <div slot="no-body" class="tabs-container px-6 pt-6">
                <div class="vx-row">
                    <h3 class="ml-2">Cài đặt ca làm việc theo ngày</h3>
                </div>
                <!-- Content Row -->
                <div class="vx-row">
                    <div class="vx-col md:w-1/3 w-full mt-2">
                        <vs-input class="w-full" label="Mã nhân viên" :disabled="true"
                                  v-model="employeeInfo.employeeCode"
                                  v-validate="'required'" name="employeeCode"/>
                        <span class="text-danger text-sm">{{ errors.first('employeeCode') }}</span>
                    </div>
                    <div class="vx-col md:w-1/3 w-full mt-2">
                        <vs-input class="w-full" label="Họ tên" :disabled="true"
                                  v-model="employeeInfo.fullName"
                                  v-validate="'required'" name="fullName"/>
                        <span class="text-danger text-sm">{{ errors.first('fullName') }}</span>
                    </div>
                </div>

                <div style="height: 30px"></div>

                <div class="vx-row no-scroll-content">
                    <calendar-view
                        ref="calendar"
                        :displayPeriodUom="calendarView"
                        :show-date="showDate"
                        :events="simpleCalendarEvents"
                        :eventTop="windowWidth <= 400 ? '2rem' : '3rem'"
                        eventBorderHeight="0px"
                        eventContentHeight="1.65rem"
                        class="theme-default"
                        @click-date="openAddNewEvent"
                        @click-event="openEditEvent">

                        <div slot="header" class="mb-4">

                            <div class="vx-row no-gutter">

                                <!-- Month Name -->
                                <div class="vx-col w-1/3 items-center sm:flex hidden">
                                    <!-- Add new event button -->
                                </div>

                                <!-- Current Month -->
                                <div
                                    class="vx-col sm:w-1/3 w-full sm:my-0 my-3 flex sm:justify-end justify-center order-last">
                                    <div class="flex items-center">
                                        <feather-icon
                                            :icon="$vs.rtl ? 'ChevronRightIcon' : 'ChevronLeftIcon'"
                                            @click="updateMonth(-1)"
                                            svgClasses="w-5 h-5 m-1"
                                            class="cursor-pointer bg-primary text-white rounded-full"/>

                                        <span class="mx-3 text-xl font-medium whitespace-no-wrap">{{
                                                showDateString
                                            }}</span>

                                        <feather-icon
                                            :icon="$vs.rtl ? 'ChevronLeftIcon' : 'ChevronRightIcon'"
                                            @click="updateMonth(1)"
                                            svgClasses="w-5 h-5 m-1"
                                            class="cursor-pointer bg-primary text-white rounded-full"/>
                                    </div>
                                </div>

                                <div class="vx-col sm:w-1/3 w-full flex justify-center">
                                    <template v-for="(view, index) in calendarViewTypes">
                                        <vs-button
                                            v-if="calendarView === view.val"
                                            :key="String(view.val) + 'filled'"
                                            type="filled"
                                            class="p-3 md:px-8 md:py-3"
                                            :class="{'border-l-0 rounded-l-none': index, 'rounded-r-none': calendarViewTypes.length !== index+1}"
                                            @click="calendarView = view.val"
                                        >{{ view.label }}
                                        </vs-button>
                                        <vs-button
                                            v-else
                                            :key="String(view.val) + 'border'"
                                            type="border"
                                            class="p-3 md:px-8 md:py-3"
                                            :class="{'border-l-0 rounded-l-none': index, 'rounded-r-none': calendarViewTypes.length !== index+1}"
                                            @click="calendarView = view.val"
                                        >{{ view.label }}
                                        </vs-button>
                                    </template>

                                </div>
                            </div>


                        </div>
                    </calendar-view>
                </div>


                <!--CREATE EVENT-->
                <vs-prompt
                    class="calendar-event-dialog"
                    title="Thêm ca làm việc"
                    accept-text="Thêm"
                    cancel-text="Đóng"
                    @accept="addEvent"
                    :is-valid="validForm"
                    :active.sync="activePromptAddEvent">


                    <div class="content-popup">

                        <div class="my-8">
                            <vs-input name="group-name" v-validate="'required'" :maxlength="50" class="w-full"
                                      label-placeholder="Tên nhóm làm việc"
                                      v-model="groupName"></vs-input>
                        </div>
                        <div class="my-8">
                            <vs-input name="work-shift-name" v-validate="'required'" :maxlength="50" class="w-full"
                                      label-placeholder="Tên ca làm việc"
                                      v-model="workShiftName"></vs-input>
                        </div>
                        <div class="my-8">
                            <small class="date-label">Ngày làm việc</small>
                            <datepicker :format="'dd/MM/yyyy'" name="start-date" v-model="startDate" :disabled="true"
                                        style="width: 100%;"></datepicker>
                        </div>

                        <div class="my-8">
                            <small class="date-label">Thời gian bắt đầu</small>
                            <v-select :value="startHourString" :clearable="false" :options="timeSpans"
                                      v-validate="'required'" @input="startHour => updateStartHour(startHour)"/>
                        </div>

                        <div class="my-8">
                            <small class="date-label">Thời gian kết thúc</small>
                            <v-select :value="endHourString" :clearable="false" :options="timeSpans"
                                      v-validate="'required'" @input="endHour => updateEndHour(endHour)"/>
                        </div>

                        <div class="my-5">
                            <label class="vs-input--label"></label>
                            <vs-checkbox v-model="advanceSetting" @input=" checked=> showAdvanceSetting(checked)">Cài
                                đặt nâng cao
                            </vs-checkbox>
                        </div>

                        <div v-if="advanceSetting">
                            <div class="my-8">
                                <vs-input class="w-full" label="Linh động(phút)" v-model="bufferTime" name="bufferTime"
                                          v-validate="'required|numeric'"/>
                                <span class="text-danger text-sm">{{ errors.first('bufferTime') }}</span>
                            </div>

                            <div class="my-8">
                                <small class="date-label">Chấp nhận checkin trước</small>
                                <v-select :value="allowCheckInBeforeString" :clearable="false" :options="timeSpans"
                                          v-validate="'required'"
                                          @input="allowCheckInBefore => updateAllowCheckInBefore(allowCheckInBefore)"/>
                            </div>

                            <div class="my-8">
                                <small class="date-label">Chấp nhận checkin sau</small>
                                <v-select :value="allowCheckInAfterString" :clearable="false" :options="timeSpans"
                                          v-validate="'required'"
                                          @input="allowCheckInAfter => updateAllowCheckInAfter(allowCheckInAfter)"/>
                            </div>

                            <div class="my-8">
                                <small class="date-label">Chấp nhận checkout trước</small>
                                <v-select :value="allowCheckOutBeforeString" :clearable="false" :options="timeSpans"
                                          v-validate="'required'"
                                          @input="allowCheckOutBefore => updateAllowCheckOutBefore(allowCheckOutBefore)"/>
                            </div>

                            <div class="my-8">
                                <small class="date-label">Chấp nhận checkout sau</small>
                                <v-select :value="allowCheckOutAfterString" :clearable="false" :options="timeSpans"
                                          v-validate="'required'"
                                          @input="allowCheckOutAfter => updateAllowCheckOutAfter(allowCheckOutAfter)"/>
                            </div>

                        </div>
                    </div>
                </vs-prompt>

                <!-- EDIT EVENT -->
                <vs-prompt
                    class="calendar-event-dialog"
                    title="Thông tin ca làm việc"
                    accept-text="Sửa"
                    cancel-text="Xóa"
                    @accept="editEvent"
                    @cancel="removeEvent"
                    :is-valid="validForm"
                    :active.sync="activePromptEditEvent">

                    <div class="content-popup">

                        <div class="my-8">
                            <vs-input name="event-name" v-validate="'required'" class="w-full"
                                      label-placeholder="Titlte" readonly
                                      v-model="title"></vs-input>
                        </div>

                        <div class="my-8">
                            <vs-input name="group-name" v-validate="'required'" :maxlength="50" class="w-full"
                                      label-placeholder="Tên nhóm làm việc" :disabled="disabledFrom"
                                      v-model="groupName"></vs-input>
                        </div>
                        <div class="my-8">
                            <vs-input name="work-shift-name" v-validate="'required'" :maxlength="50" class="w-full"
                                      label-placeholder="Tên ca làm việc" :disabled="disabledFrom"
                                      v-model="workShiftName"></vs-input>
                        </div>
                        <div class="my-8">
                            <small class="date-label">Ngày làm việc</small>
                            <datepicker :format="'dd/MM/yyyy'" name="start-date" v-model="startDate" :disabled="true"
                                        style="width: 100%;"></datepicker>
                        </div>

                        <div class="my-8">
                            <small class="date-label">Thời gian bắt đầu</small>
                            <v-select :value="startHourString" :disabled="disabledFrom" :clearable="false"
                                      :options="timeSpans" v-validate="'required'"
                                      @input="startHour => updateStartHour(startHour)"/>
                        </div>

                        <div class="my-8">
                            <small class="date-label">Thời gian kết thúc</small>
                            <v-select :value="endHourString" :disabled="disabledFrom" :clearable="false"
                                      :options="timeSpans" v-validate="'required'"
                                      @input="endHour => updateEndHour(endHour)"/>
                        </div>

                        <div class="my-5">
                            <label class="vs-input--label"></label>
                            <vs-checkbox v-model="advanceSetting" @input=" checked=> showAdvanceSetting(checked)">Cài
                                đặt nâng cao
                            </vs-checkbox>
                        </div>

                        <div v-if="advanceSetting">
                            <div class="my-8">
                                <vs-input :disabled="disabledFrom" class="w-full" label="Linh động(phút)"
                                          v-model="bufferTime" name="bufferTime"
                                          v-validate="'required|numeric'"/>
                                <span class="text-danger text-sm">{{ errors.first('bufferTime') }}</span>
                            </div>

                            <div class="my-8">
                                <small class="date-label">Chấp nhận checkin trước</small>
                                <v-select :disabled="disabledFrom" :value="allowCheckInBeforeString" :clearable="false"
                                          :options="timeSpans" v-validate="'required'"
                                          @input="allowCheckInBefore => updateAllowCheckInBefore(allowCheckInBefore)"/>
                            </div>

                            <div class="my-8">
                                <small class="date-label">Chấp nhận checkout sau</small>
                                <v-select :disabled="disabledFrom" :value="allowCheckInAfterString" :clearable="false"
                                          :options="timeSpans" v-validate="'required'"
                                          @input="allowCheckInAfter => updateAllowCheckInAfter(allowCheckInAfter)"/>
                            </div>

                            <div class="my-8">
                                <small class="date-label">Chấp nhận checkout trước</small>
                                <v-select :disabled="disabledFrom" :value="allowCheckOutBeforeString" :clearable="false"
                                          :options="timeSpans" v-validate="'required'"
                                          @input="allowCheckOutBefore => updateAllowCheckOutBefore(allowCheckOutBefore)"/>
                            </div>

                            <div class="my-8">
                                <small class="date-label">Chấp nhận checkout sau</small>
                                <v-select :disabled="disabledFrom" :value="allowCheckOutAfterString" :clearable="false"
                                          :options="timeSpans" v-validate="'required'"
                                          @input="allowCheckOutAfter => updateAllowCheckOutAfter(allowCheckOutAfter)"/>
                            </div>

                        </div>
                    </div>

                </vs-prompt>


                <!-- Save & Reset Button -->
                <div class="vx-row">
                    <div class="vx-col w-full">
                        <div class="mt-8 flex flex-wrap items-center justify-end mb-5">
                            <!--<vs-button class="ml-auto mt-2" @click="saveChanges" :disabled="!validateForm">{{`${isUpdate ?-->
                            <!--'Cập nhật': 'Tạo mới'}`}}-->
                            <!--</vs-button>-->
                            <vs-button class="ml-4 mt-2" type="border" color="warning" @click="employeeList">Quay lại
                            </vs-button>
                        </div>
                    </div>
                </div>
            </div>
        </vx-card>
    </div>
</template>

<script>

import vSelect from 'vue-select';
import Datepicker from 'vuejs-datepicker';
import moduleCalendar from '@/store/calendar/moduleCalendar.js';
import {CalendarView, CalendarViewHeader} from "vue-simple-calendar"
import {vi} from "vuejs-datepicker/src/locale";
import moment from 'moment';
import utils from "../../../components/utils";
import flatPickr from 'vue-flatpickr-component';
import 'flatpickr/dist/flatpickr.css';

require("vue-simple-calendar/static/css/default.css");


export default {
    components: {
        vSelect,
        Datepicker,
        CalendarView,
        CalendarViewHeader,
        flatPickr
    },
    data() {
        return {


            configdateTimePicker: {
                enableTime: true,
                dateFormat: 'd/m/Y H:i'
            },

            timeSpans: [],
            employeeInfo: {},
            events: [],

            showDate: new Date(),
            showDateString: moment(this.showDate).format('DD/MM/YYYY'),

            disabledFrom: false,
            id: 0,
            title: '',
            startDate: '',
            endDate: '',
            groupName: '',
            workShiftName: '',
            endHourString: '',
            endHour: 0,
            startHourString: '',
            startHour: 0,
            bufferTime: 0,
            allowCheckInBefore: 0,
            allowCheckInAfter: 0,
            allowCheckOutBefore: 0,
            allowCheckOutAfter: 0,

            allowCheckInBeforeString: '',
            allowCheckInAfterString: '',
            allowCheckOutBeforeString: '',
            allowCheckOutAfterString: '',

            advanceSetting: false,

            langVi: vi,
            calendarView: 'month',

            activePromptAddEvent: false,
            activePromptEditEvent: false,

            calendarViewTypes: [
                {
                    label: "Tháng",
                    val: "month"
                },
                {
                    label: "Tuần",
                    val: "week"
                }
            ]
        }
    },
    created() {
        let employeeId = this.$route.query.id;

        let timeDuration = 30, timeSpans = [];
        for (let i = 0; i < 24 * 60; i = i + timeDuration) {
            timeSpans.push({value: i, label: utils.minuteToHour(i)});
        }
        this.timeSpans = timeSpans;


        if (employeeId) {
            setTimeout(() => {
                this.$vs.loading();
                this.$crm.post('/employee/find-by-id/' + employeeId).then((response) => {
                    this.$vs.loading.close();

                    this.employeeInfo = response.data;
                    this.loadEvents();
                }).catch((err) => {
                    this.$vs.loading.close();
                    return this.$vs.notify({
                        text: err.response.data.message || err.response.data.error_description || err.response.data.error,
                        iconPack: 'feather',
                        icon: 'icon-alert-circle',
                        color: 'danger'
                    });
                });
            }, 0);


        }

        //load time

        this.$store.registerModule('calendar', moduleCalendar);
        this.$store.dispatch("calendar/fetchEvents");
        this.$store.dispatch("calendar/fetchEventLabels");

    },
    beforeDestroy() {
        this.$store.unregisterModule('calendar')
    },
    computed: {
        simpleCalendarEvents() {
            return this.events;
        },
        validForm() {
            let form1 = this.groupName !== ''
                && this.workShiftName !== ''
                && this.startHour !== null
                && this.endHour !== null
                && this.startHourString !== ''
                && this.endHourString !== ''
                && !this.disabledFrom
                && this.startHour < this.endHour;
            if (this.advanceSetting) {
                return form1
                    && !isNaN(this.bufferTime)
                    && this.allowCheckInAfter !== null
                    && this.allowCheckInAfterString !== ''
                    && this.allowCheckInBefore !== null
                    && this.allowCheckInBeforeString !== ''
                    && this.allowCheckOutAfter !== null
                    && this.allowCheckOutAfterString !== ''
                    && this.allowCheckOutBefore !== null
                    && this.allowCheckOutBeforeString !== '';
            }
            return form1;
        },
        disabledDatesTo() {
            return {to: new Date(this.startDate)}
        },
        disabledDatesFrom() {
            return {from: new Date(this.endDate)}
        },
        calendarLabels() {
            return this.$store.state.calendar.eventLabels
        },
        windowWidth() {
            return this.$store.state.windowWidth
        }
    },

    methods: {
        updateEndHour(hour) {
            this.endHourString = hour.label;
            this.endHour = hour.value;
        },
        updateStartHour(hour) {
            this.startHourString = hour.label;
            this.startHour = hour.value;
        },

        updateAllowCheckInBefore(hour) {
            this.allowCheckInBeforeString = hour.label;
            this.allowCheckInBefore = hour.value;
        },
        updateAllowCheckInAfter(hour) {
            this.allowCheckInAfterString = hour.label;
            this.allowCheckInAfter = hour.value;
        },
        updateAllowCheckOutBefore(hour) {
            this.allowCheckOutBeforeString = hour.label;
            this.allowCheckOutBefore = hour.value;
        },
        updateAllowCheckOutAfter(hour) {
            this.allowCheckOutAfterString = hour.label;
            this.allowCheckOutAfter = hour.value;
        },

        employeeList() {
            this.$router.push(`/user/manage-work-shift-by-day${this.employeeInfo.organizationBranchId ? `?branch_id=${this.employeeInfo.organizationBranchId}` : ''}`).catch(() => {
            })
        },
        addEvent() {
            const objPost = {
                "advanceSetting": this.advanceSetting,
                "allowCheckInAfter": this.allowCheckInAfter,
                "allowCheckInBefore": this.allowCheckInBefore,
                "allowCheckOutAfter": this.allowCheckOutAfter,
                "allowCheckOutBefore": this.allowCheckOutBefore,
                "bufferTime": this.bufferTime,
                "employeeId": this.$route.query.id,
                "groupName": this.groupName,
                "organizationBranchId": this.$route.query.branch_id,
                "workDate": this.startDate.toISOString(),
                "startTime": this.startHour,
                "workShiftName": this.workShiftName,
                "endTime": this.endHour
            };

            const obj = {
                id: this.id,
                title: this.groupName + ' - ' + this.workShiftName,
                startDate: this.startDate,
                endDate: this.endDate,
                extendedProps: {
                    groupName: this.groupName,
                    workShiftName: this.workShiftName,

                    startHour: this.startHour,
                    startHourString: this.startHourString,
                    endHour: this.endHour,
                    endHourString: this.endHourString,


                    bufferTime: this.bufferTime,
                    advanceSetting: false,
                    allowCheckInAfter: this.allowCheckInAfter,
                    allowCheckInBefore: this.allowCheckInBefore,
                    allowCheckOutAfter: this.allowCheckOutAfter,
                    allowCheckOutBefore: this.allowCheckOutBefore,

                    allowCheckInBeforeString: this.allowCheckInBeforeString,
                    allowCheckInAfterString: this.allowCheckInAfterString,
                    allowCheckOutBeforeString: this.allowCheckOutBeforeString,
                    allowCheckOutAfterString: this.allowCheckOutAfterString,
                }
            };


            this.$vs.loading();
            this.$crm.post('/employee-workshift-by-day/create', objPost).then((response) => {
                this.$vs.loading.close();
                this.$vs.notify({
                    text: "Tạo mới ca làm việc thành công",
                    iconPack: 'feather',
                    icon: 'icon-alert-circle',
                    color: 'success'
                });
                this.loadEvents();
            }).catch((err) => {
                this.$vs.loading.close();
                return this.$vs.notify({
                    text: err.response.data.message || err.response.data.error_description || err.response.data.error,
                    iconPack: 'feather',
                    icon: 'icon-alert-circle',
                    color: 'danger'
                });
            });
        },
        showAdvanceSetting(checked) {
            this.advanceSetting = checked;
        },
        updateMonth(val) {
            this.showDate = this.$refs.calendar.getIncrementedPeriod(val);
            this.showDateString = moment(this.showDate).format('DD/MM/YYYY');

            this.events = [];
            this.$vs.loading();
            let start = new Date();
            start.setDate(start.getDate() - 30);
            let end = new Date();
            end.setDate(end.getDate() + 30);

            this.$crm.get(`employee-workshift-by-day/find-all?employeeId=${this.$route.query.id}&start=${start.toISOString()}&end=${end.toISOString()}`).then((response) => {
                this.$vs.loading.close();
                this.employeeInfo = response.data;
                response.data.forEach(v => {
                    this.events.push({
                        id: v.id,
                        title: v.workShiftName,
                        startDate: new Date(v.workStartAt),
                        endDate: new Date(v.workEndAt)
                    })
                });
            }).catch((err) => {
                this.$vs.loading.close();
                return this.$vs.notify({
                    text: err.response.data.message || err.response.data.error_description || err.response.data.error,
                    iconPack: 'feather',
                    icon: 'icon-alert-circle',
                    color: 'danger'
                });
            });

        },
        clearFields() {
            this.title = this.endDate = "";
            this.id = 0;
            this.groupName = '';
            this.workShiftName = '';
            this.startHour = 0;
            this.endHour = 0;
            this.bufferTime = 0;

            this.endHourString = '';
            this.endHour = 0;
            this.startHourString = '';
            this.startHour = 0;

            this.allowCheckInBefore = 0;
            this.allowCheckInAfter = 0;
            this.allowCheckOutBefore = 0;
            this.allowCheckOutAfter = 0;

            this.allowCheckInBeforeString = '';
            this.allowCheckInAfterString = '';
            this.allowCheckOutBeforeString = '';
            this.allowCheckOutAfterString = '';
            this.advanceSetting = false;
        },
        promptAddNewEvent(date) {
            this.addNewEventDialog(date);
        },
        addNewEventDialog(date) {
            this.clearFields();
            this.startDate = date;
            this.endDate = date;
            this.activePromptAddEvent = true;
            this.disabledFrom = false;
        },
        openAddNewEvent(date) {
            this.addNewEventDialog(date);
        },
        openEditEvent(event) {
            this.disabledFrom = true;
            const e = event;

            if (e.startDate.getTime() > new Date().getTime()) {
                this.disabledFrom = false;
            }

            this.id = e.id;
            this.title = e.title;
            this.startDate = e.startDate;
            this.endDate = e.endDate;
            this.groupName = e.originalEvent.extendedProps.groupName;
            this.workShiftName = e.originalEvent.extendedProps.workShiftName;

            this.startHour = e.originalEvent.extendedProps.startHour;
            this.startHourString = e.originalEvent.extendedProps.startHourString;
            this.endHour = e.originalEvent.extendedProps.endHour;
            this.endHourString = e.originalEvent.extendedProps.endHourString;


            this.bufferTime = e.originalEvent.extendedProps.bufferTime;
            this.advanceSetting = false;
            this.allowCheckInAfter = e.originalEvent.extendedProps.allowCheckInAfter;
            this.allowCheckInBefore = e.originalEvent.extendedProps.allowCheckInBefore;
            this.allowCheckOutAfter = e.originalEvent.extendedProps.allowCheckOutAfter;
            this.allowCheckOutBefore = e.originalEvent.extendedProps.allowCheckOutBefore;

            this.allowCheckInBeforeString = e.originalEvent.extendedProps.allowCheckInBeforeString;
            this.allowCheckInAfterString = e.originalEvent.extendedProps.allowCheckInAfterString;
            this.allowCheckOutBeforeString = e.originalEvent.extendedProps.allowCheckOutBeforeString;
            this.allowCheckOutAfterString = e.originalEvent.extendedProps.allowCheckOutAfterString;
            this.activePromptEditEvent = true;
        },
        editEvent() {
            let workDate = new Date(this.startDate.getTime());
            workDate.setHours(0, 0, 0, 0);
            const objPost = {
                "id": this.id,
                "advanceSetting": this.advanceSetting,
                "allowCheckInAfter": this.allowCheckInAfter,
                "allowCheckInBefore": this.allowCheckInBefore,
                "allowCheckOutAfter": this.allowCheckOutAfter,
                "allowCheckOutBefore": this.allowCheckOutBefore,
                "bufferTime": this.bufferTime,
                "employeeId": this.$route.query.id,
                "groupName": this.groupName,
                "organizationBranchId": this.$route.query.branch_id,
                "workDate": workDate.toISOString(),
                "startTime": this.startHour,
                "workShiftName": this.workShiftName,
                "endTime": this.endHour
            };

            this.events = [];


            this.$vs.loading();
            this.$crm.post('/employee-workshift-by-day/update', objPost).then((response) => {
                this.$vs.loading.close();
                this.$vs.notify({
                    text: "Cập nhật ca làm việc thành công",
                    iconPack: 'feather',
                    icon: 'icon-alert-circle',
                    color: 'success'
                });
                this.loadEvents();
            }).catch((err) => {
                this.$vs.loading.close();
                return this.$vs.notify({
                    text: err.response.data.message || err.response.data.error_description || err.response.data.error,
                    iconPack: 'feather',
                    icon: 'icon-alert-circle',
                    color: 'danger'
                });
            });


        },
        removeEvent() {
            if (this.startDate.getTime() < new Date().getTime()) {
                this.$vs.notify({
                    color: 'danger',
                    title: 'Không thể xóa',
                    text: 'Bạn không thể xóa ngày làm việc cũ hơn ngày hiện tại.'
                })
            } else {
                this.$vs.dialog({
                    type: 'confirm',
                    color: 'danger',
                    title: `Xác nhận xoá`,
                    text: `Bạn có chắc là muốn xóa ca làm việc này.`,
                    accept: () => {
                        this.$vs.loading();
                        this.$crm.post('/employee-workshift-by-day/delete/' + this.id).then((response) => {
                            this.$vs.loading.close();
                            this.$vs.notify({
                                text: "Xóa ca làm việc thành công",
                                iconPack: 'feather',
                                icon: 'icon-alert-circle',
                                color: 'danger'
                            });
                            this.loadEvents();
                        }).catch((err) => {
                            this.$vs.loading.close();
                            return this.$vs.notify({
                                text: err.response.data.message || err.response.data.error_description || err.response.data.error,
                                iconPack: 'feather',
                                icon: 'icon-alert-circle',
                                color: 'danger'
                            });
                        });
                    },
                    acceptText: "Xoá",
                    cancelText: "Huỷ"
                })
            }
        },
        loadEvents() {

            this.$vs.loading();
            let start = new Date();
            start.setDate(start.getDate() - 30);
            let end = new Date();
            end.setDate(end.getDate() + 30);

            this.$crm.get(`employee-workshift-by-day/find-all?employeeId=${this.$route.query.id}&start=${start.toISOString()}&end=${end.toISOString()}`).then((response) => {
                this.$vs.loading.close();
                this.events = [];

                response.data.forEach(v => {
                    this.events.push({
                        id: v.id,
                        title: v.groupName + ' - ' + v.workShiftName,
                        startDate: new Date(v.workStartAt),
                        endDate: new Date(v.workEndAt),
                        extendedProps: {
                            groupName: v.groupName,
                            workShiftName: v.workShiftName,

                            startHour: v.startTime,
                            startHourString: this.timeSpans.find(timeSpan => timeSpan.value == v.startTime).label,
                            endHour: v.endTime,
                            endHourString: this.timeSpans.find(timeSpan => timeSpan.value == v.endTime).label,

                            bufferTime: v.bufferTime,
                            advanceSetting: false,
                            allowCheckInAfter: v.allowCheckInAfter,
                            allowCheckInBefore: v.allowCheckInBefore,
                            allowCheckOutAfter: v.allowCheckOutAfter,
                            allowCheckOutBefore: v.allowCheckOutBefore,

                            allowCheckInBeforeString: this.timeSpans.find(timeSpan => timeSpan.value == v.allowCheckInBefore).label,
                            allowCheckInAfterString: this.timeSpans.find(timeSpan => timeSpan.value == v.allowCheckInAfter).label,
                            allowCheckOutBeforeString: this.timeSpans.find(timeSpan => timeSpan.value == v.allowCheckOutBefore).label,
                            allowCheckOutAfterString: this.timeSpans.find(timeSpan => timeSpan.value == v.allowCheckOutAfter).label,

                        }
                    })
                });

            }).catch((err) => {
                this.$vs.loading.close();
                return this.$vs.notify({
                    text: err.response.data.message || err.response.data.error_description || err.response.data.error,
                    iconPack: 'feather',
                    icon: 'icon-alert-circle',
                    color: 'danger'
                });
            });
        }
    }
}
</script>

<style lang="scss">
@import "@/assets/scss/vuexy/apps/simple-calendar.scss";


.vs-dialog {
    overflow-x: hidden;
    overflow-y: auto;
    max-height: calc(100% - 20px);
}


.vs-dialog::-webkit-scrollbar {
    width: 0px;
}

.vs-dialog-cancel {
    transform: translate(0px, -8px) !important;
}

</style>
